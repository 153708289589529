<template>
  <b-button
    class="is-dark is-outlined back-button"
    is-light
    :expanded="field.properties.basic.isBlock"
    @click="handleUpdate()"
  >
    {{ getTranslation(`${field.id}_label`, field.properties.basic.btnText) }}
  </b-button>
</template>
  
<script >
import { useLocalizationStore } from '@/store/localizationStore';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  },
  errors: {
    type: Object,
    required: true
  },
  fieldEvents: {
    type: Object,
    default: () => ({})
  },
  customEventHandler: {
    type: Function,
    default: () => {}
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const localizationStore = useLocalizationStore();
  const {
    getTranslation
  } = localizationStore;
  const handleUpdate = async () => {
    if (props.customEventHandler) {
      await props.customEventHandler();
    }
    emit('update');
  };
  return {
    getTranslation,
    handleUpdate
  };
};
export default __sfc_main;
</script>
